var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"van-slide-up"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"file-preview-page"},[(_vm.showTitle)?_c('div',{staticClass:"preview-page-title"},[_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.file.fileName))])]):_vm._e(),_c('div',{staticClass:"scroll-wrap",attrs:{"id":"scrollWrap"}},[_c('div',{ref:"previewContent",staticClass:"preview-page-content",style:({
        transformOrigin: ((_vm.style.transformOrigin.x) + "px " + (_vm.style.transformOrigin.y) + "px"),
      })},[_c('div',{ref:"top",staticClass:"top boundary"}),_c('div',{ref:"left",staticClass:"left boundary"}),_c('div',{ref:"right",staticClass:"right boundary"}),_c('div',{ref:"bottom",staticClass:"bottom boundary"}),_vm._l((_vm.oldPositionList),function(item,index){return [(item.signType == 4)?_vm._l((_vm.pageNum),function(num,index2){return _c('div',{key:index + 'signType4' + index2,staticClass:"sealStyle24",style:({
              right: '0px',
              top: _vm.calcPositionY(item.y, num, true) + 'px',
              width: ((_vm.getWidthAndHeight(item.width / _vm.pageNum)) + "px"),
              height: ((_vm.getWidthAndHeight(item.height)) + "px"),
            })},[_c('img',{style:({
                transform: ("translate(-" + (_vm.getWidthAndHeight(
                  item.width / _vm.pageNum
                ) *
                  (num - 1)) + "px"),
              }),attrs:{"src":item.sealKey,"alt":""}})])}):[_c('div',{key:index,class:item.signType > 2 ? 'sealStyle2' : 'signPosition2',style:({
              left: item.x * _vm.ratio + 'px',
              top: _vm.calcPositionY(item.y, item.page, true) + 'px',
              width: ((_vm.getWidthAndHeight(item.width)) + "px"),
              height: ((_vm.getWidthAndHeight(item.height)) + "px"),
            })},[(item.dateFlag)?_c('div',{staticClass:"time"},[_c('img',{staticClass:"currenDateBase64",attrs:{"src":_vm.textBecomeImg(item.signDate, 14, '#000'),"alt":""}})]):_vm._e(),_c('img',{attrs:{"src":item.sealKey,"alt":""}})])]]}),_c('ul',{ref:"imgWrap",staticClass:"file-img-list",attrs:{"id":"imgWrap"}},[_vm._l((_vm.imgList),function(item,index){return _c('li',{key:index,staticClass:"file-img-item"},[_c('img',{style:({ height: _vm.computedY + 'px' }),attrs:{"src":item,"alt":""}})])}),_vm._l((_vm.hasValueParamList),function(variable){return [(variable.dataType != 11)?_c('li',{key:variable.componentId,staticClass:"file-variable",style:({
          left: variable.x * _vm.ratio + 'px',
          top: _vm.calcPositionY(variable.y, variable.page, true) + 'px',
          width:
            variable.fontSize * _vm.ratio >= 12
              ? variable.width * _vm.ratio + 'px'
              : (variable.width * _vm.ratio) /
              ((variable.fontSize * _vm.ratio) / 12) +
              'px',
          height: variable.height * _vm.ratio + 'px',
          fontSize:
            variable.fontSize * _vm.ratio >= 12
              ? variable.fontSize * _vm.ratio + 'px'
              : 12 + 'px',
          fontFamily: _vm.getCurFontFamilyByValue(variable.font).name,
        })},[_c('div',{style:({
            transform: ("scale(" + (variable.fontSize * _vm.ratio >= 12
                ? 1
                : (variable.fontSize * _vm.ratio) / 12) + ")"),
            display: 'inline-block',
            transformOrigin: '0 0',
          })},[_vm._v(" "+_vm._s(variable.varValue ? variable.varValue : variable.varTitle)+" ")])]):_c('li',{key:variable.componentId,staticClass:"file-variable",style:({
          left: variable.x * _vm.ratio + 'px',
          top: _vm.calcPositionY(variable.y, variable.page, true) + 'px',
          width: variable.width * _vm.ratio + 'px',
          height: variable.height * _vm.ratio + 'px',
          fontSize:
            variable.fontSize * _vm.ratio >= 12
              ? variable.fontSize * _vm.ratio + 'px'
              : 12 + 'px',
          fontFamily: _vm.getCurFontFamilyByValue(variable.font).name,
        })},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":variable.varValue,"alt":""}})])]})],2)],2)]),_c('div',{staticClass:"footer"},[(_vm.showBack && _vm.isCurFileBeHandled)?_c('van-button',{staticClass:"footer-button",attrs:{"type":"primary"},on:{"click":_vm.handleChangeToFirstFileToBeProcess}},[_vm._v("已签署，切换下一份")]):(_vm.showBack)?_c('van-button',{staticClass:"footer-button",attrs:{"type":"primary"},on:{"click":_vm.handleEmitBack}},[_vm._v("预览完毕 开始签署")]):[_c('van-button',{staticClass:"footer-button",attrs:{"type":"primary"},on:{"click":_vm.close}},[_vm._v("退出预览")]),(_vm.showDownload)?_c('div',{staticClass:"footer-button",attrs:{"type":"primary"}},[_c('span',{staticClass:"operate-button",on:{"click":function($event){$event.stopPropagation();return _vm.handleToDownload($event)}}},[_vm._v("下载")])]):_vm._e()]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }